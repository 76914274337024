const userNavigationTypes = {
  all: ['ANA', 'MAN', 'TEC', 'LID', 'AUT', 'PRO', 'SUPER'],
  authorizer: ['AUT'],
  technician: ['TEC'],
  manager: ['MAN'],
  analyst: ['ANA'],
  leader: ['LID'],
  provider: ['PRO'],
  techPro: ['TEC', 'PRO'],
  anaPro: ['ANA', 'PRO'],
  manPro: ['PRO', 'MAN'],
  anaTecLiAut: ['ANA', 'TEC', 'PRO', 'LID', 'AUT'],
  anaTecLi: ['ANA', 'TEC', 'PRO', 'LID'],
  manLiAut: ['MAN', 'LID', 'AUT'],
  manLiAna: ['MAN', 'LID', 'ANA'],
  client: ['ANA', 'LID', 'AUT'],
  authLid: ['AUT', 'LID'],
  manAut: ['MAN', 'AUT'],
  manAna: ['ANA', 'MAN'],
  anaTec: ['ANA', 'TEC', 'PRO'],
  anaTecOnly: ['ANA', 'TEC'],
  manTec: ['MAN', 'TEC', 'PRO'],
  manLi: ['MAN', 'LID'],
  super: ['SUPER']
}

const checkUserType = (routeTypes, userTypes) => {
  if (userNavigationTypes[routeTypes].indexOf(userTypes) > -1) { return true }
  return false
}

const userArrayTypes = [
  ['MAN', 'Gerente'],
  ['SUPER', 'Super'],
  ['PRO', 'Fornecedor'],
  ['AUT', 'Autorizador'],
  ['ANA', 'Analista'],
  ['TEC', 'Técnico'],
  ['LID', 'Lider']
]

const userTypes = {
  MAN: 'Gerente',
  SUPER: 'Super',
  TEC: 'Técnico',
  PRO: 'Fornecedor',
  AUT: 'Autorizador',
  ANA: 'Analista',
  LID: 'Lider'
}

const userTypesChat = {
  MAN: 'FIELD',
  SUPER: 'FIELD',
  TEC: 'Técnico',
  PRO: 'Técnico',
  AUT: 'Autorizador',
  ANA: 'Analista',
  LID: 'Analista'
}

const userOsMessage = {
  MAN: 'Ticket em progresso',
  SUPER: 'Ticket em progresso',
  TEC: 'Seus ticket em progresso',
  PRO: 'Tickets novos',
  ANA: 'Sues ticket em progresso',
  LID: 'Ticket em progresso da sua empresa',
  AUT: 'Ticket aguardando autorização',
  AG: 'Ticket Aguardando autorização de adicionais',
  AC: 'Tickets novos'
}

const userBudgetMessage = {
  MAN: 'Orçamentos em progresso',
  SUPER: 'Orçamentos em progresso',
  PRE: 'Orçamentos pré cadastrados',
  TEC: 'Orçamentos em progresso',
  PRO: 'Orçamentos em progresso',
  LID: 'Orçamentos em progresso da sua empresa',
  AUT: 'Orçamentos aguardando autorização',
  AG: 'Orçamentos Aguardando autorização de adicionais',
  YOU: 'Orçamentos em progresso',
  NOVO: 'Orçamentos novos',
  ANA: 'Orçamentos em progresso'
}

export {
  checkUserType,
  userArrayTypes,
  userTypes,
  userTypesChat,
  userOsMessage,
  userBudgetMessage
}
