import { initializeApp } from 'firebase/app'
import { getMessaging, getToken, onMessage, isSupported } from 'firebase/messaging'
import 'firebase/compat/messaging'

import { firebaseConfig } from '../config'
import api from './api'

const setNotifications = async ({ userId, token }) => {
  const app = initializeApp(firebaseConfig)
  const pair = 'BJlVhtjFGo94nvuEMZvtSXqmXcY55BQvc_g-A38CW7K6DocVxG7efQyBN-qJPhn-zzXL3U0HnTg-K5r-lHON21Y'

  if (app !== null) {
    const messaging = getMessaging(app)
    const suported = isSupported()

    if (suported) {
      getToken(messaging, { vapidKey: pair }).then((currentToken) => {
        if (currentToken) {
          const savedNotification = window.localStorage.getItem('notification')
          if (currentToken !== savedNotification) {
            window.localStorage.setItem('notification', currentToken)
            api.post('/notification', { userId, token, notification: currentToken })
          }
        } else { console.log('No registration token available. Request permission to generate one.') }
      }).catch((err) => { console.log('An error occurred while retrieving token. ', err) })

      onMessage(messaging, (payload) => {
        const { title, body } = payload.data
        const options = {
          body,
          data: payload.data,
          icon: '/favicon/favicon-64.png',
          badge: '/favicon/favicon-96.png',
          image: '/resources/og-image.png',
          vibrate: [200, 100, 200, 100, 200, 100, 200],
          silent: false,
          tag: 'agion-field-notification'
        }

        if (window.Notification === null || window.Notification === undefined) {
          return false
        } else if (window.Notification.permission === 'granted') {
          if ('serviceWorker' in navigator && 'PushManager' in window) {
            const url = new URL(payload.data.pathname, window.location.origin).href
            if (window.location.href !== url) {
              navigator.serviceWorker.ready.then((registration) => { registration.showNotification(title, options) })
              navigator.serviceWorker.getRegistration('/firebase-cloud-messaging-push-scope').then(registration => {
                registration.showNotification(title, options)
              })
            }
          } else {
            window.pushButton.textContent = 'Sem suporte a notificação push'
          }
        }
      })
    }
  }
}

export default setNotifications
