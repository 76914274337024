import React from 'react'
import { colors } from '../../theme'

const Logo = ({ style, color = null, primary = null, secondary = null }) => {
  const fill = color || colors.primary

  return (
    <svg viewBox='0 0 735.07 141.82' style={style}>
      <path style={{ fill: secondary || fill }} d='M468.49,31h-23q-13.9,0-20.4,6t-6.5,18.12v51.34a4.28,4.28,0,0,0,4.28,4.27h2.28a4.27,4.27,0,0,0,4.27-4.27V81.8a4.28,4.28,0,0,1,4.27-4.28h29.18a4.27,4.27,0,0,0,4.27-4.27v-.12a4.27,4.27,0,0,0-4.27-4.27H433.73a4.27,4.27,0,0,1-4.27-4.27v-9.4q0-8,3.87-11.8t12.2-3.82h23a4.27,4.27,0,1,0,0-8.54Z' />
      <path style={{ fill: secondary || fill }} d='M493.33,31a5.41,5.41,0,0,0-5.41,5.41v69a5.42,5.42,0,0,0,10.83,0V36.44A5.42,5.42,0,0,0,493.33,31Z' />
      <path style={{ fill: secondary || fill }} d='M566.27,102.25H542.85q-8.33,0-12.19-3.82t-3.88-11.79V75.24h33.39a4.33,4.33,0,1,0,0-8.66H526.78V55.19q0-8,3.88-11.8t12.19-3.82h23a4.27,4.27,0,1,0,0-8.54h-23q-13.9,0-20.4,6T516,55.19V86.64q0,12.08,6.49,18.12t20.4,6h23.42a4.28,4.28,0,0,0,0-8.55Z' />
      <path style={{ fill: secondary || fill }} d='M630.55,102.25H609.18q-7.87,0-11.63-3.82t-3.76-11.79V36.44a5.41,5.41,0,0,0-10.82,0v50.2q0,12.08,6.32,18.12t19.89,6h21.37a4.28,4.28,0,0,0,0-8.55Z' />
      <path style={{ fill: secondary || fill }} d='M698,34.9Q690.44,31,677.56,31H653.09a4.25,4.25,0,0,0-4.25,4.25v71.27a4.25,4.25,0,0,0,4.25,4.25h24.47q12.87,0,20.39-3.88a22.7,22.7,0,0,0,10.89-12.47q3.36-8.61,3.36-23.54t-3.36-23.47A22.68,22.68,0,0,0,698,34.9ZM699,89.32a17.61,17.61,0,0,1-7.53,9.86c-3.45,2-8.11,3.07-14,3.07h-17.9V39.57h17.9c5.84,0,10.5,1,14,3.08s6,5.36,7.53,9.91,2.33,10.72,2.33,18.47S700.59,84.8,699,89.32Z' />
      <path style={{ fill: primary || fill }} d='M172.48,42.13a5.26,5.26,0,0,0-3.84,1.25L141.12,66.9a5.28,5.28,0,1,0,6.87,8l23.15-19.79a30.91,30.91,0,1,1-25-15.1,5.28,5.28,0,0,0,.53-10.55A41.47,41.47,0,1,0,176.09,44,5.3,5.3,0,0,0,172.48,42.13Z' />
      <path style={{ fill: primary || fill }} d='M197.92,29.44a5.27,5.27,0,0,0-5.28,5.28v67.87a5.29,5.29,0,1,0,10.57,0V34.72A5.28,5.28,0,0,0,197.92,29.44Z' />
      <path style={{ fill: primary || fill }} d='M360.65,29.44a5.28,5.28,0,0,0-5.28,5.28V88.3l-48.81-57a5.28,5.28,0,0,0-9.29,3.43v67.87a5.28,5.28,0,1,0,10.56,0V49l48.81,57a5.28,5.28,0,0,0,9.3-3.44V34.72A5.28,5.28,0,0,0,360.65,29.44Z' />
      <path style={{ fill: primary || fill }} d='M69.76,36.58A5.3,5.3,0,0,0,65.2,34h0a5.27,5.27,0,0,0-4.56,2.62l-37,63.35a5.28,5.28,0,1,0,9.12,5.33L65.2,49.72l32.46,55.54a5.29,5.29,0,1,0,9.13-5.33Z' />
      <path style={{ fill: primary || fill }} d='M250.24,29.45A41.47,41.47,0,1,0,291.7,70.92,41.51,41.51,0,0,0,250.24,29.45Zm0,72.37a30.9,30.9,0,1,1,30.9-30.9A30.94,30.94,0,0,1,250.24,101.82Z' />
      <rect style={{ fill: secondary || fill }} x='390.94' y='4.41' width='3' height='133' />
    </svg>
  )
}

export default Logo
