import React, { Suspense, lazy } from 'react'
import { Routes, Route, createBrowserRouter, RouterProvider } from 'react-router-dom'
import AuthLayout from './AuthLayout'
// PRIVATE PAGES

import Loading from '../components/Loading'

// PUBLIC PAGES
const SignIn = lazy(() => import('../pages/Auth/SignIn'))
const SignUp = lazy(() => import('../pages/Auth/SignUp'))
const Done = lazy(() => import('../pages/Auth/Done'))
const Recover = lazy(() => import('../pages/Auth/Recover'))
const PasswordChange = lazy(() => import('../pages/Auth/PasswordChange'))

const NotFound = lazy(() => import('../pages/404'))
// PUBLIC PAGES

// PRIVATE PAGES
const Home = lazy(() => import('../pages/Home'))
const Monitor = lazy(() => import('../pages/Monitor'))
const Config = lazy(() => import('../pages/Config'))

const Chat = lazy(() => import('../pages/Chat'))

const BudgetIndex = lazy(() => import('../pages/Budget/Index'))
const BudgetForm = lazy(() => import('../pages/Budget/Form'))
const BudgetView = lazy(() => import('../pages/Budget/View'))

const BillProvidersIndex = lazy(() => import('../pages/Bill/Providers/Index'))
const BillProvidersIndexActive = lazy(() => import('../pages/Bill/Providers/IndexActive'))
const BillProvidersView = lazy(() => import('../pages/Bill/Providers/View'))

const BillCompaniesIndex = lazy(() => import('../pages/Bill/Companies/Index'))
const BillCompaniesIndexActive = lazy(() => import('../pages/Bill/Companies/IndexActive'))
const BillCompaniesView = lazy(() => import('../pages/Bill/Companies/View'))

const ForcePasswordChange = lazy(() => import('../pages/Users/ForcePasswordChange'))
const ForcePasswordChangeNew = lazy(() => import('../pages/Users/ForcePasswordChange/new'))
const UserTransfer = lazy(() => import('../pages/Users/UserTransfer'))

const ProviderIndex = lazy(() => import('../pages/Provider/Index'))
const ProviderForm = lazy(() => import('../pages/Provider/Form'))
const ProviderView = lazy(() => import('../pages/Provider/View'))

const AuthorizerForm = lazy(() => import('../pages/Users/Authorizer/Form'))
const AuthorizerAdd = lazy(() => import('../pages/Users/Authorizer/Add'))
const AuthorizerView = lazy(() => import('../pages/Users/Authorizer/View'))

const LeaderForm = lazy(() => import('../pages/Users/Leader/Form'))
const LeaderAdd = lazy(() => import('../pages/Users/Leader/Add'))
const LeaderView = lazy(() => import('../pages/Users/Leader/View'))

const AnalystForm = lazy(() => import('../pages/Users/Analyst/Form'))
const AnalystAdd = lazy(() => import('../pages/Users/Analyst/Add'))
const AnalystView = lazy(() => import('../pages/Users/Analyst/View'))

const TechnicianIndex = lazy(() => import('../pages/Users/Technician/Index'))
const TechnicianForm = lazy(() => import('../pages/Users/Technician/Form'))
const TechnicianAdd = lazy(() => import('../pages/Users/Technician/Add'))
const TechnicianView = lazy(() => import('../pages/Users/Technician/View'))

const ManagerIndex = lazy(() => import('../pages/Users/Manager/Index'))
const ManagerForm = lazy(() => import('../pages/Users/Manager/Form'))
const ManagerAdd = lazy(() => import('../pages/Users/Manager/Add'))
const ManagerView = lazy(() => import('../pages/Users/Manager/View'))

const ProfileForm = lazy(() => import('../pages/Users/Profile/Form'))
const UserForm = lazy(() => import('../pages/Users/Profile/Form/users'))
const ProfileView = lazy(() => import('../pages/Users/Profile/View'))
const UserView = lazy(() => import('../pages/Users/Profile/User'))

const CompanyIndex = lazy(() => import('../pages/Company/Index'))
const CompanyForm = lazy(() => import('../pages/Company/Form'))
const CompanyView = lazy(() => import('../pages/Company/View'))

const HelpProfileIndex = lazy(() => import('../pages/Help/profile'))
const HelpTicketIndex = lazy(() => import('../pages/Help/ticket'))
const HelpNoteIndex = lazy(() => import('../pages/Help/note'))
const HelpFinIndex = lazy(() => import('../pages/Help/fin'))
const HelpHome = lazy(() => import('../pages/Help/home'))

const HelpIndex = lazy(() => import('../pages/Help'))
const HelpForm = lazy(() => import('../pages/Help/form'))
const HelpView = lazy(() => import('../pages/Help/view'))

const IncidentIndex = lazy(() => import('../pages/Incident/index'))
const IncidentView = lazy(() => import('../pages/Incident/view'))
const IncidentForm = lazy(() => import('../pages/Incident/form'))

const PanelIndex = lazy(() => import('../pages/Panel/index'))
const PanelView = lazy(() => import('../pages/Panel/view'))
const PanelForm = lazy(() => import('../pages/Panel/form'))

const OmbudsmanForm = lazy(() => import('../pages/Contact/ombudsman'))
const ComplimentForm = lazy(() => import('../pages/Contact/compliment'))
const ContactHome = lazy(() => import('../pages/Contact/home'))

const ContactIndex = lazy(() => import('../pages/Contact'))
const ContactIndexAll = lazy(() => import('../pages/Contact/indexAll'))
const ContactView = lazy(() => import('../pages/Contact/view'))

const LocalForm = lazy(() => import('../pages/Local/Form'))
const LocalView = lazy(() => import('../pages/Local/View'))

const DashboardIndex = lazy(() => import('../pages/Dashboard'))

const PreIndex = lazy(() => import('../pages/Pre/Index'))
const PreIndexAll = lazy(() => import('../pages/Pre/Index/all'))
const PreView = lazy(() => import('../pages/Pre/View'))
const PreForm = lazy(() => import('../pages/Pre/Form'))

const AuditIndex = lazy(() => import('../pages/Audit/Index'))
const AuditView = lazy(() => import('../pages/Audit/View'))

// TICKET
const TicketForm = lazy(() => import('../pages/Ticket/Form'))
const TicketIndex = lazy(() => import('../pages/Ticket/Index'))
const TicketSum = lazy(() => import('../pages/Ticket/Index/sum'))
const TicketView = lazy(() => import('../pages/Ticket/View'))

// TODO: adicionar errobondary
// You can provide a way better UX than this when your app throws errors by providing your own ErrorBoundary or errorElement prop on your route.
const MyRoutes = () => (
  <Suspense fallback={<Loading />}>
    <Routes>
      <Route element={<AuthLayout login />}>
        <Route index element={<SignIn />} />
        <Route path='*' component={NotFound} />
      </Route>
      <Route element={<AuthLayout />}>
        <Route path='/cadastro' element={<SignUp />} />
        <Route path='/feito' element={<Done />} />
        <Route path='/recuperar-senha' exact element={<Recover />} />
      </Route>

      <Route element={<AuthLayout isPrivate />}>
        <Route path='/ouvidoria/novo' exact element={<OmbudsmanForm />} />
        <Route path='/elogio/novo' exact element={<ComplimentForm />} />

        <Route path='/ajuda' exact element={<HelpHome />} />
        <Route path='/ajuda/ticket' exact element={<HelpTicketIndex />} />
        <Route path='/ajuda/nota' exact element={<HelpNoteIndex />} />
        <Route path='/ajuda/perfil' exact element={<HelpProfileIndex />} />
        <Route path='/ajuda/financeiro' exact element={<HelpFinIndex />} />

        <Route path='/incidente/:protocol' exact element={<IncidentView />} />
        <Route path='/incidentes' exact element={<IncidentIndex />} />
        <Route path='/incidente/novo' exact element={<IncidentForm />} />
        <Route path='/incidente/:protocol/editar' exact element={<IncidentForm />} />

        <Route path='/painel/:slug' exact element={<PanelView />} />
        <Route path='/paineis' exact element={<PanelIndex />} />
        <Route path='/painel/novo' exact element={<PanelForm />} />
        <Route path='/painel/:slug/editar' exact element={<PanelForm />} />

        <Route path='/fale-conosco' exact element={<ContactHome />} />

        <Route path='/inicio' exact element={<Home />} />
        <Route path='/trocar/senha' exact element={<PasswordChange />} />

        <Route path='/tickets' exact element={<TicketIndex />} />
        <Route path='/ticket/:protocol' exact element={<TicketView />} />
        <Route path='/ticket/inspecionar/:protocol' exact element={<TicketView />} />

        <Route path='/ticket/:protocol/chat' exact element={<Chat />} />

        <Route path='/orcamento/:protocol' exact element={<BudgetView />} />
        <Route path='/orcamento/:protocol/chat' exact element={<Chat />} />
        <Route path='/orcamentos' exact element={<BudgetIndex />} />

        <Route path='/perfil' exact element={<ProfileView />} />
        <Route path='/perfil/editar' exact element={<ProfileForm />} />
      </Route>

      <Route element={<AuthLayout isPrivate sideBar={false} />}>
        <Route path='/monitoramento' exact element={<Monitor />} />
      </Route>

      <Route element={<AuthLayout isPrivate viewer='provider' />}>
        <Route path='/faturas' exact element={<BillProvidersIndexActive />} />
        <Route path='/faturas/todas' exact element={<BillProvidersIndex />} />
      </Route>

      <Route element={<AuthLayout isPrivate viewer='manPro' />}>
        <Route path='/tecnico/:username' exact element={<TechnicianView />} />
        <Route path='/tecnicos' exact element={<TechnicianIndex />} />

        <Route path='/fornecedores/faturas/' exact element={<BillProvidersIndexActive />} />
        <Route path='/fornecedores/fatura/:protocol' exact element={<BillProvidersView />} />
        <Route path='/fornecedores/faturas/todas' exact element={<BillProvidersIndex />} />
      </Route>

      <Route element={<AuthLayout isPrivate viewer='manAut' />}>
        <Route path='/tickets/consumo' exact element={<TicketSum />} />
      </Route>

      <Route element={<AuthLayout isPrivate viewer='manager' superOnly />}>
        <Route path='/configuracoes' exact element={<Config />} />
        <Route path='/dashboard' exact element={<DashboardIndex />} />
        <Route path='/auditoria' exact element={<AuditIndex />} />
        <Route path='/auditoria/:_id' exact element={<AuditView />} />
      </Route>

      <Route element={<AuthLayout isPrivate viewer='manager' />}>
        <Route path='/pre' exact element={<PreIndex />} />
        <Route path='/pre/todos' exact element={<PreIndexAll />} />
        <Route path='/pre/:_id' exact element={<PreView />} />
        <Route path='/pre/:_id/aceitar' exact element={<PreForm />} />

        <Route path='/tickets/consumo/:companyId' exact element={<TicketSum />} />

        <Route path='/fornecedores' exact element={<ProviderIndex />} />
        <Route path='/fornecedor/novo' exact element={<ProviderForm />} />
        <Route path='/fornecedor/:_id/editar' exact element={<ProviderForm />} />
        <Route path='/fornecedor/:_id' exact element={<ProviderView />} />

        <Route path='/autorizador/:companyId/adicionar' exact element={<AuthorizerAdd />} />
        <Route path='/autorizador/:companyId/novo' exact element={<AuthorizerForm />} />
        <Route path='/autorizador/:_id/editar' exact element={<AuthorizerForm />} />
        <Route path='/autorizador/:_id' exact element={<AuthorizerView />} />

        <Route path='/lider/:companyId/adicionar' exact element={<LeaderAdd />} />
        <Route path='/lider/:companyId/novo' exact element={<LeaderForm />} />
        <Route path='/lider/:_id/editar' exact element={<LeaderForm />} />
        <Route path='/lider/:_id' exact element={<LeaderView />} />

        <Route path='/analista/:companyId/adicionar' exact element={<AnalystAdd />} />
        <Route path='/analista/:companyId/novo' exact element={<AnalystForm />} />
        <Route path='/analista/:_id/editar' exact element={<AnalystForm />} />
        <Route path='/analista/:_id' exact element={<AnalystView />} />

        <Route path='/tecnico/:providerId/novo' exact element={<TechnicianForm />} />
        <Route path='/tecnico/:providerId/adicionar' exact element={<TechnicianAdd />} />
        <Route path='/tecnico/:_id/editar' exact element={<TechnicianForm />} />

        <Route path='/gerentes' exact element={<ManagerIndex />} />
        <Route path='/gerente/novo' exact element={<ManagerForm />} />
        <Route path='/gerente/adicionar' exact element={<ManagerAdd />} />
        <Route path='/gerente/:_id/editar' exact element={<ManagerForm />} />
        <Route path='/gerente/:_id' exact element={<ManagerView />} />

        <Route path='/empresas/' exact element={<CompanyIndex />} />
        <Route path='/empresas/fatura/:protocol' exact element={<BillCompaniesView />} />
        <Route path='/empresas/faturas' exact element={<BillCompaniesIndexActive />} />
        <Route path='/empresas/faturas/todas' exact element={<BillCompaniesIndex />} />

        <Route path='/empresa/nova' exact element={<CompanyForm />} />
        <Route path='/empresa/:_id/editar' exact element={<CompanyForm />} />
        <Route path='/empresa/:_id' exact element={<CompanyView />} />

        <Route path='/local/:companyId/novo' exact element={<LocalForm />} />
        <Route path='/local/:_id/editar' exact element={<LocalForm />} />
        <Route path='/local/:_id' exact element={<LocalView />} />

        <Route path='/usuario/:username' exact element={<UserView />} />
        <Route path='/usuario/:username/editar' exact element={<UserForm />} />
        <Route path='/usuario/:username/senha' exact element={<ForcePasswordChangeNew />} />
        {/* <Route path='/usuario/:username/transferir' exact element={<UserTransferNew />} /> */}

        {/* REMOVE */}
        <Route path='/force/senha' exact element={<ForcePasswordChange />} />
        <Route path='/transferir' exact element={<UserTransfer />} />
        <Route path='/transferir/fornecedor' exact element={<UserTransfer />} />
        {/* <Route path="/fatura/:protocol" exact element={<BillView />} /> */}
        {/* REMOVE */}

        <Route path='/ajuda/lista' exact element={<HelpIndex />} />
        <Route path='/ajuda/novo' exact element={<HelpForm />} />
        <Route path='/ajuda/:_id/editar' exact element={<HelpForm />} />
        <Route path='/ajuda/:_id' exact element={<HelpView />} />

        <Route path='/fale-conosco/lista' exact element={<ContactIndex />} />
        <Route path='/fale-conosco/lista/todos' exact element={<ContactIndexAll />} />
        <Route path='/fale-conosco/:_id' exact element={<ContactView />} />

      </Route>

      <Route element={<AuthLayout isPrivate viewer='manLiAut' />}>
        <Route path='/ticket/novo' exact element={<TicketForm />} />

        <Route path='/orcamento/novo' exact element={<BudgetForm />} />
        <Route path='/orcamento/:protocol/editar' exact element={<BudgetForm />} />
      </Route>

    </Routes>
  </Suspense>
)

const router = createBrowserRouter([
  { path: '*', Component: MyRoutes }
])

const AppRoutes = () => (
  <RouterProvider router={router} />
)

export default AppRoutes
