import React, { createContext, useCallback, useState, useContext } from 'react'
import { jwtDecode } from 'jwt-decode'

import api, { blobAPI, fileAPI } from '../services/api'
import { removeSession, checkSession } from '../services/session'
import setNotifications from '../services/firebase'
import socket from '../services/socket'

const AuthContext = createContext({})

export const AuthProvider = ({ children }) => {
  const [data, setData] = useState(useCallback(() => {
    const token = window.localStorage.getItem('token')
    if (token) {
      const user = window.localStorage.getItem('user')
      const parserdUser = JSON.parse(user)
      checkSession({ _id: parserdUser._id, token })
        .then((response) => {
          if (!response.data.check) {
            window.localStorage.clear()
            socket.disconnect()
            window.location.href = '/'
            return {}
          }
        })
      // TODO: ADD https://www.npmjs.com/package/react-secure-storage
      const currentTime = new Date().getTime() / 1000
      if (currentTime > jwtDecode(token).exp) {
        const token = window.localStorage.getItem('token')
        const user = window.localStorage.getItem('user')
        const parserdUser = JSON.parse(user)
        removeSession({ _id: parserdUser._id, token })
        window.localStorage.clear()
        socket.disconnect()
        return {}
      }

      const types = window.localStorage.getItem('types')
      const parserdTypes = JSON.parse(types)
      const typesIndex = window.localStorage.getItem('typesIndex')

      api.defaults.headers.authorization = `Bearer ${token}`
      api.defaults.headers.type = `${parserdTypes[typesIndex]._id}`
      fileAPI.defaults.headers.authorization = `Bearer ${token}`
      blobAPI.defaults.headers.authorization = `Bearer ${token}`

      setNotifications({ userId: parserdUser._id, token })

      socket.auth.authorization = token
      socket.connect()
      socket.on('disconnect', (reason) => {
        if (reason === 'io server disconnect') { socket.connect() }
      })

      return { user: parserdUser, types: parserdTypes, type: parserdTypes[parseInt(typesIndex)], token }
    }
    return {}
  }), [api, fileAPI, blobAPI, socket, setNotifications])

  const tryNotification = useCallback(() => {
    const token = window.localStorage.getItem('token')
    const user = window.localStorage.getItem('user')
    const parserdUser = JSON.parse(user)
    setNotifications({ userId: parserdUser._id, token })
  }, [])

  const tryLocalization = useCallback(() => {
    // check if the browser has geolocation and if it is enabled
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        const { latitude, longitude } = position.coords
        return { latitude, longitude }
      }, (error) => { console.error('Get error:', error) })
    }
  }, [])

  const singIn = useCallback(async ({ username, password }) => {
    const response = await api.post('session', { username, password })
    if (response.data.status === 'error') { throw response.data.message }
    const token = response.data.user.session
    const user = response.data.user
    const types = response.data.types
    window.localStorage.setItem('token', token)
    window.localStorage.setItem('user', JSON.stringify(user))
    window.localStorage.setItem('typesIndex', 0)
    window.localStorage.setItem('types', JSON.stringify(types))
    window.localStorage.setItem('sidebar', 'false')
    window.localStorage.setItem('nightmode', 'true')
    api.defaults.headers.authorization = `Bearer ${token}`
    fileAPI.defaults.headers.authorization = `Bearer ${token}`
    blobAPI.defaults.headers.authorization = `Bearer ${token}`
    setNotifications({ userId: user._id, token })
    socket.auth.authorization = token
    socket.connect()
    socket.on('disconnect', (reason) => {
      if (reason === 'io server disconnect') { socket.connect() }
    })
    setData({ user, type: types[0], types })
  }, [api, fileAPI, blobAPI, socket, setNotifications])

  const signOut = useCallback(() => {
    const token = window.localStorage.getItem('token')
    const user = window.localStorage.getItem('user')
    const parserdUser = JSON.parse(user)
    removeSession({ _id: parserdUser._id, token })
    window.localStorage.clear()
    socket.disconnect()
    setData({})
  }, [api, socket, window.localStorage])

  return (<AuthContext.Provider value={{ user: data.user, type: data.type, types: data.types, token: data.token, singIn, tryNotification, tryLocalization, signOut }}>{children}</AuthContext.Provider>)
}

export const useAuth = () => {
  const context = useContext(AuthContext)
  if (!context) { throw new Error('useAuth só pode ser usando dentro do AuthProvider') }
  return context
}
