import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'

import { checkUserType } from '../services/userType'
import Navigation from '../navigation'
import { useAuth } from '../hooks'

const AuthLayout = ({ viewer = 'all', isPrivate = false, sideBar = true, login = false, superOnly }) => {
  const { user, type } = useAuth()

  const path = window.location.pathname
  const params = new URLSearchParams(window.location.search)
  const newPath = params.get('para')

  if (superOnly && !user?.super) {
    return <Navigate to={isPrivate ? '/' : (newPath || '/inicio')} replace />
  }

  if (isPrivate && !!user) {
    if (checkUserType(viewer, type?.type)) {
      return <Navigation sideBar={sideBar}><Outlet /></Navigation>
    }
    return <Navigate to={isPrivate ? '/' : (newPath || '/inicio')} replace />
  }

  if (isPrivate === !!user) {
    return <Outlet />
  }

  if (isPrivate !== !!user) {
    if (login) {
      return <Navigate to={(newPath || '/inicio')} replace />
    }
    if (isPrivate) {
      return <Navigate to={isPrivate ? (path === '/inicio' ? '/' : `/?para=${path}`) : (newPath || '/inicio')} replace />
    } else {
      return <Outlet />
    }
  }
}

export default AuthLayout
