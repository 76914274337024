import api from '../api'

const checkSession = async ({ _id, token }) => {
  return await api.post('/session/check', { userId: _id, token })
}

const removeSession = async ({ _id, token }) => {
  return await api.post('/session/delete', { userId: _id, token })
}

export { checkSession, removeSession }
