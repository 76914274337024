import React, { useState, useCallback, useEffect } from 'react'
import { Fade, Avatar, Badge, Tooltip, SwipeableDrawer as MuiDrawer, AppBar as MuiAppBar, Toolbar, Typography, Box, Paper, IconButton, List, Link, Divider, Dialog, DialogTitle, ListItem, ListItemButton, ListItemAvatar, ListItemText } from '@mui/material'
import { Plus, User, Folder, FilePlus, Users, DollarSign, Cpu, Box as BoxIcon, Monitor, BellOff, Map, BarChart2, AlertOctagon, Headphones, HelpCircle, Menu as MenuIcon, Briefcase, ChevronLeft } from 'react-feather'
import useMediaQuery from '@mui/material/useMediaQuery'
import { NavLink, useNavigate } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import moment from 'moment-timezone'

import { checkUserType, userTypes } from '../services/userType'
import { useAuth, useTheme } from '../hooks'
import api, { healthAPI } from '../services/api'
import socket from '../services/socket'
import packa from '../../package.json'

import Lace from '../components/Lace'
import SideListItem from '../components/SideListItem'
import Logo from '../components/Logo'
import Menu from './menu'

const drawerWidth = 240

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden'
})

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(6)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(7)} + 1px)`
  }
})

const StyledBadge = styled(Badge)(({ theme, con }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: con === 'true' ? '#44b700' : '#b70000',
    color: con === 'true' ? '#44b700' : '#b70000',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""'
    }
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0
    }
  }
}))

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar
}))

const AppBar = styled(MuiAppBar, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    ml: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}))

// const CustomButom = styled(Button)(({ theme, open }) => ({
//   position: 'absolute',
//   minWidth: '30px',
//   height: '30px',
//   width: '30px',
//   zIndex: 9999,
//   top: 50,
//   p: 0.1,
//   left: 40,
//   transition: theme.transitions.create(['left'], {
//     easing: theme.transitions.easing.sharp,
//     duration: theme.transitions.duration.leavingScreen
//   }),
//   ...(open && {
//     left: 222,
//     transition: theme.transitions.create(['left'], {
//       easing: theme.transitions.easing.sharp,
//       duration: theme.transitions.duration.enteringScreen
//     })
//   })
// }))

const Drawer = styled(MuiDrawer)(({ theme, open }) => ({
  width: 555,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme)
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme)
  })
}))

const Navigation = ({ sideBar, children }) => {
  const { dark } = useTheme()
  const { user, types, type, tryNotification, tryLocalization } = useAuth()
  const navigate = useNavigate()

  const [notificationAtive, setNotificationAtive] = useState(true)
  const [localizationAtive, setLocalizationAtive] = useState(true)

  const [isConnected, setIsConnected] = useState(true)
  const [version, setVersion] = useState('')
  const [lace, setLace] = useState('')
  const [load, setLoad] = useState(false)
  const [time, setTime] = useState('')

  const [dialog, setDialog] = useState(false)
  const dialogOpen = () => setDialog(true)
  const dialogClose = () => setDialog(false)

  const handleTypesIndex = useCallback((index) => {
    window.localStorage.setItem('typesIndex', index)
    window.location.reload()
  }, [])

  const smScreen = useMediaQuery(theme => theme.breakpoints.up('sm'))
  // const mdScreen = useMediaQuery(theme => theme.breakpoints.up('md'))

  const [open, setOpen] = useState(() => {
    return (window.localStorage.getItem('sidebar') === 'true')
  })

  const handleDrawer = useCallback(() => {
    window.localStorage.setItem('sidebar', !open)
    setOpen(!open)
  }, [open])

  const getVersion = useCallback(async () => {
    const response = await healthAPI.get('/health')
    setVersion(response.data.backend)
    setLoad(true)
  }, [])

  const getLace = useCallback(async () => {
    const response = await api.get('/configuration/lace')
    setLace(response.data)
  }, [])

  const onConnect = () => { setIsConnected(true) }
  const onDisconnect = () => { setIsConnected(false) }

  const checkGeoAndNot = useCallback(async () => {
    const permissionGeo = await navigator?.permissions?.query({ name: 'geolocation' })
    const permissionNote = await navigator?.permissions?.query({ name: 'notifications' })

    if (permissionGeo?.state === 'granted') {
      setLocalizationAtive(true)
    }
    if (permissionNote?.state === 'granted') {
      setNotificationAtive(true)
    }
  }, [])

  useEffect(() => {
    socket.on('connect', onConnect)
    socket.on('disconnect', onDisconnect)
    checkGeoAndNot()
    getVersion()
    getLace()
    setTime(moment().format('H:mm'))
    setInterval(() => {
      setTime(moment().format('H:mm'))
    }, 30000)
  }, [])

  return (
    <Box sx={{ display: 'flex', height: '100vh' }} key='home'>
      {/* <CustomButom
        onClick={handleDrawer}
        variant='contained'
        open={open}
      >
        <ChevronLeft size={20} />
        <ChevronRight size={20} />
      </CustomButom> */}

      <Dialog onClose={dialogClose} open={dialog}>
        <DialogTitle>Selecione uma conta</DialogTitle>
        <List sx={{ pt: 0 }}>
          {types.map((type, index) => (
            <ListItem disableGutters key={index}>
              <ListItemButton onClick={() => handleTypesIndex(index)}>
                <ListItemAvatar>
                  <Avatar>
                    {userTypes[type.type].split(' ')[0][0]}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={userTypes[type.type]} secondary={`${type?.company?.name || ''}${type.provider?.name || ''}`} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Dialog>

      <AppBar enableColorOnDark position='fixed' open={open} elevation={0} color={dark ? 'secondary' : 'primary'}>
        <Toolbar>
          {!open &&
            <IconButton edge='start' sx={{ mr: 2 }} onClick={handleDrawer} color='inherit'>
              <MenuIcon size={25} />
            </IconButton>}

          <IconButton color={dark ? 'primary' : 'secondary'} aria-label='Voltar uma pagina' onClick={() => navigate(-1)} edge='start'>
            <ChevronLeft size={25} />
          </IconButton>

          <Typography component='h1' variant='h6' color='inherit' noWrap sx={{ display: 'flex', flexDirection: 'row', textDecoration: 'none' }}>
            <Link noWrap color='inherit' component={NavLink} to='/'>
              <Logo style={{ height: 30 }} color={dark ? null : '#303030'} />
            </Link>
          </Typography>

          <Box sx={{ flexGrow: 1 }} />

          {/* TODO: Olhar documentação de fade */}
          <Fade in appear timeout={250}>
            <Tooltip title={moment.tz.guess()} placement='bottom'>
              <time>{time}</time>
            </Tooltip>
          </Fade>

          <Box sx={{ flexGrow: 1 }} />

          <Fade in appear timeout={250}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>

              {!notificationAtive &&
                <Tooltip title='Notificações desabilitadas, por favor habilite no seu navegador.' placement='bottom'>
                  <IconButton size='small' color='error' sx={{ mr: 1 }} onClick={tryNotification}>
                    <BellOff size={20} />
                  </IconButton>
                </Tooltip>}

              {!localizationAtive &&
                <Tooltip title='Localização desabilidata, por favor habilite no seu navegador.' placement='bottom'>
                  <IconButton size='small' color='error' sx={{ mr: 2 }} onClick={tryLocalization}>
                    <Map size={20} />
                  </IconButton>
                </Tooltip>}

              <Link color='inherit' component='button' onClick={dialogOpen} underline='none' sx={{}}>
                <StyledBadge anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} con={`${isConnected}`} overlap='circular' variant='dot'>
                  <Avatar
                    src={`https://api.dicebear.com/6.x/initials/svg?seed=${user.name}&backgroundColor=00897b,00acc1,039be5,1e88e5,3949ab,43a047,5e35b1,7cb342,8e24aa,c0ca33,d81b60,e53935,f4511e,fb8c00,fdd835,ffb300,ffd5dc,ffdfbf`}
                    alt={user.name}
                    sx={{ mr: 1 }}
                  />
                </StyledBadge>
              </Link>

              {smScreen &&
                <Link color='inherit' component='button' onClick={dialogOpen} underline='none' sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography variant='overline'>
                    {user.name}
                  </Typography>
                  <Typography variant='caption' sx={{ p: 0, mt: -1, display: 'flex', flexDirection: 'row', maxWidth: 200 }}>
                    {userTypes[type.type]}
                    <Box sx={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                      {type.company ? ` - ${type.company.name}` : ''}
                      {type.provider ? ` - ${type.provider.name}` : ''}
                    </Box>
                  </Typography>
                </Link>}

            </Box>
          </Fade>
          <Menu version={version} />
        </Toolbar>
      </AppBar>

      <Drawer variant={(smScreen && sideBar) ? 'permanent' : 'temporary'} onClose={handleDrawer} onOpen={handleDrawer} open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawer} color='inherit'>
            <MenuIcon size={25} />
          </IconButton>
        </DrawerHeader>

        <Fade in appear timeout={250}>
          <div>

            {checkUserType('manager', type?.type) &&
              <>
                <List sx={{ p: 0 }}>
                  <SideListItem open={open} name='Fornecedores' to='/fornecedores' Icon={Users} />
                  <SideListItem open={open} name='Empresas' to='/empresas' Icon={Briefcase} />
                  <SideListItem open={open} name='Gerentes' to='/gerentes' Icon={Cpu} />
                  <SideListItem open={open} name='Faturas fornecedor' to='/fornecedores/faturas/' Icon={DollarSign} />
                  <SideListItem open={open} name='Faturas empresas' to='/empresas/faturas' Icon={DollarSign} />
                </List>
                <Divider sx={{ mx: 2, m: 0.5 }} />
                <List sx={{ p: 0 }}>
                  <SideListItem open={open} name='Monitor de tickets' to='/monitoramento' Icon={Monitor} />
                  <SideListItem open={open} name='Todos tickets' to='/tickets' Icon={BoxIcon} />
                  <SideListItem open={open} name='Novo ticket' to='/ticket/novo' Icon={Plus} />
                </List>
                <Divider sx={{ mx: 2, m: 0.5 }} />
                <List sx={{ p: 0 }}>
                  <SideListItem open={open} name='Todos orçamentos' to='/orcamentos' Icon={Folder} />
                  <SideListItem open={open} name='Novo orçamento' to='/orcamento/novo' Icon={FilePlus} />
                </List>
              </>}

            {checkUserType('provider', type?.type) &&
              <>
                <List sx={{ p: 0 }}>
                  <SideListItem open={open} name='Técnicos' to='/tecnicos' Icon={User} />
                  <SideListItem open={open} name='Faturas' to='/faturas' Icon={DollarSign} />
                </List>
                <Divider sx={{ mx: 2, m: 0.5 }} />
                <List sx={{ p: 0 }}>
                  <SideListItem open={open} name='Monitor de tickets' to='/monitoramento' Icon={Monitor} />
                  <SideListItem open={open} name='Todos tickets' to='/tickets' Icon={BoxIcon} />
                </List>
                <Divider sx={{ mx: 2, m: 0.5 }} />
                <List sx={{ p: 0 }}>
                  <SideListItem open={open} name='Todos orçamentos' to='/orcamentos' Icon={Folder} />
                </List>
              </>}

            {checkUserType('leader', type?.type) &&
              <>
                <List sx={{ p: 0 }}>
                  <SideListItem open={open} name='Monitor de tickets' to='/monitoramento' Icon={Monitor} />
                  <SideListItem open={open} name='Todos tickets' to='/tickets' Icon={BoxIcon} />
                  <SideListItem open={open} name='Novo ticket' to='/ticket/novo' Icon={Plus} />
                </List>
                <Divider sx={{ mx: 2, m: 0.5 }} />
                <List sx={{ p: 0 }}>
                  <SideListItem open={open} name='Todos orçamentos' to='/orcamentos' Icon={Folder} />
                  <SideListItem open={open} name='Novo orçamento' to='/orcamento/novo' Icon={FilePlus} />
                </List>
              </>}

            {checkUserType('authorizer', type?.type) &&
              <>
                <List sx={{ p: 0 }}>
                  <SideListItem open={open} name='Monitor de tickets' to='/monitoramento' Icon={Monitor} />
                  <SideListItem open={open} name='Todos tickets' to='/tickets' Icon={BoxIcon} />
                  <SideListItem open={open} name='Novo ticket' to='/ticket/novo' Icon={Plus} />
                </List>
                <Divider sx={{ mx: 2, m: 0.5 }} />
                <List sx={{ p: 0 }}>
                  <SideListItem open={open} name='Todos orçamentos' to='/orcamentos' Icon={Folder} />
                  <SideListItem open={open} name='Novo orçamento' to='/orcamento/novo' Icon={FilePlus} />
                </List>
              </>}

            {checkUserType('anaTecOnly', type?.type) &&
              <>
                <List sx={{ p: 0 }}>
                  <SideListItem open={open} name='Todos tickets' to='/tickets' Icon={BoxIcon} />
                </List>
                <Divider sx={{ mx: 2, m: 0.5 }} />
                <List sx={{ p: 0 }}>
                  <SideListItem open={open} name='Todos orçamentos' to='/orcamentos' Icon={Folder} />
                </List>
              </>}
          </div>
        </Fade>

        <Box flexGrow={1} />

        {lace.active && <Link noWrap color='inherit' target='_blank' rel='noopener noreferrer' href={lace.link || '#'}><Lace style={{ height: 50 }} color={lace.color} /></Link>}

        <List sx={{ p: 0 }}>
          {user.panel && <SideListItem open={open} name='Painéis' to='/paineis' Icon={BarChart2} closeIf={[handleDrawer, smScreen]} />}
          {user.incident && <SideListItem open={open} name='Incidentes' to='/incidentes' Icon={AlertOctagon} closeIf={[handleDrawer, smScreen]} />}
          <SideListItem open={open} name='Fale conosco' to='/fale-conosco' Icon={Headphones} closeIf={[handleDrawer, smScreen]} />
        </List>

        <Badge color='secondary' badgeContent='Em breve' overlap='circular' variant={open ? 'string' : 'dot'} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
          <List sx={{ p: 0 }}>
            <SideListItem open={open} name='Central de ajuda' to='/ajuda' Icon={HelpCircle} disabled closeIf={[handleDrawer, smScreen]} />
          </List>
        </Badge>
      </Drawer>
      <Box component='main' sx={{ flexGrow: 1, height: '100vh', overflow: 'auto', pt: 8 }}>
        {load && (packa.version !== version)
          ? <Paper color='error' sx={{ my: 9, mx: 1, padding: 2, borderRadius: 1 }}><Typography align='center' color='textSecondary'> <strong>Nova versão disponível!</strong> Limpe seu cache de navegação e atualize a página para obter a ultima versão.</Typography></Paper>
          : (<>{children}</>)}
      </Box>
    </Box>
  )
}

export default Navigation
