import React from 'react'
import { HelmetProvider } from 'react-helmet-async'

import Picker from './Picker'
import { AuthProvider, useAuth } from './Auth'
import { AppThemeProvider, useTheme } from './Theme'
import NotificationProvider from './Notification'
import useApi from './Api'

export default ({ children }) => (
  <HelmetProvider>
    <AppThemeProvider>
      <NotificationProvider>
        <AuthProvider>
          <Picker>
            {children}
          </Picker>
        </AuthProvider>
      </NotificationProvider>
    </AppThemeProvider>
  </HelmetProvider>
)

export {
  useAuth,
  useTheme,
  useApi
}
