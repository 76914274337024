import React from 'react'

import { StyledEngineProvider } from '@mui/material/styles'
import { CssBaseline } from '@mui/material'
import moment from 'moment-timezone'
import 'moment/locale/pt-br'

import AppProvider from './hooks'
import Routes from './routes'

moment.updateLocale('pt-br')

const App = () => (
  <StyledEngineProvider injectFirst>
    <AppProvider>
      <CssBaseline enableColorScheme />
      <Routes />
    </AppProvider>
  </StyledEngineProvider>
)

export default App
