import { io } from 'socket.io-client'
import config from '../config'

const socket = io(config[process.env.REACT_APP_ENV].socket, {
  auth: { token: config[process.env.REACT_APP_ENV].token },
  withCredentials: true,
  autoConnect: false,
  transports: ['websocket']
})

export default socket
