const firebaseConfig = {
  apiKey: 'AIzaSyCnkS19vIy3tHSdUTtrr2_3KxbujQnCmkw',
  authDomain: 'agion-field.firebaseapp.com',
  projectId: 'agion-field',
  storageBucket: 'agion-field.appspot.com',
  messagingSenderId: '328538752254',
  appId: '1:328538752254:web:ccccfc98c5d154b57011af',
  measurementId: 'G-XBKCG03KNV'
}

export default {
  development: {
    socket: 'http://localhost:5555/',
    api: 'http://localhost:5555/v1/',
    blob: 'http://localhost:5555/v1/static/storage/',
    token: 'jgVIaMVQa1oKQ3N8p1eDX3E4nTsfZdovqVFbg5Pz37FzChskuCiiKw0WLX0iTEE'
  },
  test: {
    socket: 'https://api.teste.agionfield.com.br/',
    api: 'https://api.teste.agionfield.com.br/v1/',
    blob: 'https://api.teste.agionfield.com.br/v1/static/storage/',
    token: 'jgVIaMVQa1oKQ3N8p1eDX3E4nTsfZdovqVFbg5Pz37FzChskuCiiKw0WLX0iTEE'
  },
  production: {
    socket: 'https://api.field.agiontecnologia.com.br/',
    api: 'https://api.field.agiontecnologia.com.br/v1/',
    blob: 'https://api.field.agiontecnologia.com.br/v1/static/storage/',
    token: 'jgVIaMVQa1oKQ3N8p1eDX3E4nTsfZdovqVFbg5Pz37FzChskuCiiKw0WLX0iTEE'
  }
}

export { firebaseConfig }
