import axios from 'axios'
import config from '../config'

const blobAPI = axios.create({
  baseURL: config[process.env.REACT_APP_ENV].blob,
  responseType: 'blob',
  headers: {
    'Content-Type': 'multipart/form-data',
    token: config[process.env.REACT_APP_ENV].token
  }
})

const fileAPI = axios.create({
  baseURL: config[process.env.REACT_APP_ENV].api,
  headers: {
    'Content-Type': 'multipart/form-data',
    token: config[process.env.REACT_APP_ENV].token
  }
})

const healthAPI = axios.create({
  baseURL: config[process.env.REACT_APP_ENV].socket,
  headers: { token: config[process.env.REACT_APP_ENV].token }
})

export default axios.create({
  baseURL: config[process.env.REACT_APP_ENV].api,
  headers: { token: config[process.env.REACT_APP_ENV].token }
})

export { blobAPI, fileAPI, healthAPI }
