import React from 'react'
import { SnackbarProvider } from 'notistack'
import { X as LogOut } from 'react-feather'

const notistackRef = React.createRef()
const onClickDismiss = key => () => {
  notistackRef.current.closeSnackbar(key)
}

const NotificationProvider = ({ children }) => (
  <SnackbarProvider
    ref={notistackRef}
    maxSnack={5}
    dense
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right'
    }}
    autoHideDuration={3000}
    preventDuplicate
    action={(key) => (
      <LogOut onClick={onClickDismiss(key)} />
    )}
  >
    {children}
  </SnackbarProvider>
)

export default NotificationProvider
