import { useEffect, useState } from 'react'

import api from '../services/api'

const useApi = (method = 'get', url, body = {}) => {
  const [data, setdata] = useState([])
  const [loading, setloading] = useState(true)
  const [error, seterror] = useState('')

  useEffect(() => {
    api({
      method,
      url,
      data: body
    }).then((response) => {
      setdata(response.data)
      setloading(false)
    }).catch((error) => {
      seterror(error)
      setloading(false)
    })
  }, [method, url])

  return { data, loading, error }
}

export default useApi
