import React from 'react'
import shadeColor from '../../services/shadeColor'

const Lace = ({ style, color = '#FF4CA8' }) => {
  return (
    <svg x='0px' y='0px' viewBox='0 0 500 500' style={style}>
      <path
        style={{ fill: shadeColor(color, -10), strokeWidth: 2.7428, strokeLinecap: 'round', strokeLinejoin: 'round', strokeMiterlimit: 10 }}
        d='M196.732,105.586c-2.228,10.702-1.403,19.764,0.616,29.114c3.696,17.117,52.507,102.665,120.386,193.464 c2.507,3.353,7.638,3.022,9.681-0.633l24.445-43.718c1.418-2.537,1.135-5.68-0.716-7.921 c-20.474-24.787-135.985-166.676-126.204-201.066C224.938,74.826,209.488,78.867,196.732,105.586'
      />

      <path
        style={{ fill: color, strokeWidth: 2.7428, strokeLinecap: 'round', strokeLinejoin: 'round', strokeMiterlimit: 10 }}
        d='M303.268,105.586c2.228,10.702,1.403,19.764-0.616,29.114c-3.696,17.117-52.507,102.665-120.386,193.464 c-2.507,3.353-7.637,3.022-9.681-0.633l-24.445-43.718c-1.418-2.537-1.135-5.68,0.716-7.921 c20.474-24.787,135.985-166.676,126.204-201.066C275.062,74.826,290.512,78.867,303.268,105.586'
      />

      <path
        style={{ fill: shadeColor(color, 10), strokeWidth: 2.7428, strokeLinecap: 'round', strokeLinejoin: 'round', strokeMiterlimit: 10 }}
        d=' M303.268,105.586c2.228,10.702,1.403,19.764-0.616,29.114c-3.696,17.117-52.507,102.665-120.386,193.464 c-2.507,3.353-7.637,3.022-9.681-0.633l-24.445-43.718c-1.418-2.537-1.135-5.68,0.716-7.921 c20.474-24.787,135.985-166.676,126.204-201.066C275.062,74.826,290.512,78.867,303.268,105.586'
      />

      <path
        style={{ fill: color, strokeWidth: 2.7428, strokeLinecap: 'round', strokeLinejoin: 'round', strokeMiterlimit: 10 }}
        d='M303.294,105.586c-2.229-10.702-6.236-22.677-10.061-36.747c-7.649-28.141-43.128-27.542-43.128-27.542 s-14.482-1.038-28.462,8.188c-5.834,3.85-10.116,9.648-12.242,16.306l-12.701,39.795c0,0,14.387-26.369,53.296-25.97 C288.907,80.015,303.294,105.586,303.294,105.586z'
      />
    </svg>
  )
}

export default Lace
