import React from 'react'
import { Drawer as MuiDrawer, AppBar as MuiAppBar, Toolbar, Typography, Box, IconButton, List, Link, Badge } from '@mui/material'
import { Menu as MenuIcon, MoreVertical, ChevronLeft, HelpCircle, Headphones } from 'react-feather'
import useMediaQuery from '@mui/material/useMediaQuery'
import { styled } from '@mui/material/styles'
import { NavLink } from 'react-router-dom'

import SideListItem from '../../components/SideListItem'
import { useTheme } from '../../hooks'
import Logo from '../Logo'

const Loading = () => {
  const { dark } = useTheme()

  const smScreen = useMediaQuery(theme => theme.breakpoints.up('sm'))
  // const mdScreen = useMediaQuery(theme => theme.breakpoints.up('md'))
  // const appBarheight = '65px'

  const open = (window.localStorage.getItem('sidebar') === 'true')
  const drawerWidth = 240

  const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    overflowX: 'hidden'
  })

  const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(6)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
      width: `calc(${theme.spacing(7)} + 1px)`
    }
  })

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar
  }))

  const AppBar = styled(MuiAppBar, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    ...(open && {
      ml: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })
    })
  }))

  const Drawer = styled(MuiDrawer)(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme)
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme)
    })
  }))

  const SideBarOption = () => (
    <>
      <Box flexGrow={1} />
      <List sx={{ p: 0 }}>
        <SideListItem name='Fale conosco' to='/fale-conosco' Icon={Headphones} />
      </List>
      <Badge color='secondary' badgeContent='Em breve' overlap='circular' variant={open ? 'string' : 'dot'} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <List sx={{ p: 0 }}>
          <SideListItem name='Central de ajuda' to='/ajuda' Icon={HelpCircle} disabled />
        </List>
      </Badge>
    </>
  )

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar enableColorOnDark position='fixed' open={open} elevation={0} color={dark ? 'secondary' : 'primary'}>
        <Toolbar sx={{ pr: 0 }}>
          {!open &&
            <IconButton edge='start' sx={{ mr: 2 }} color='inherit'>
              <MenuIcon size={25} />
            </IconButton>}
          <IconButton color={dark ? 'primary' : 'secondary'} aria-label='Voltar uma pagina' edge='start'>
            <ChevronLeft size={25} />
          </IconButton>

          <Typography component='h1' variant='h6' color='inherit' noWrap sx={{ display: 'flex', flexDirection: 'row', textDecoration: 'none' }}>
            <Link noWrap color='inherit' component={NavLink} to='/'>
              <Logo style={{ height: 30 }} color={dark ? null : '#303030'} />
            </Link>
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <IconButton color='inherit' aria-label='Sair'>
            <MoreVertical size={15} />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer variant={smScreen ? 'permanent' : 'temporary'} open={open}>
        <DrawerHeader>
          <IconButton color='inherit'>
            <MenuIcon size={25} />
          </IconButton>
        </DrawerHeader>
        <SideBarOption />
      </Drawer>
      <Box component='main' sx={{ flexGrow: 1, height: '100vh', overflow: 'auto', pt: 8, m: 1 }} />
    </Box>
  )
}

export default Loading
