import React from 'react'
import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'
import { NavLink } from 'react-router-dom'
import { colors } from '../../theme'

const SideListItem = ({ open = false, visible = true, name, to, Icon, disabled = false, closeIf }) => {
  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(() => ({ [`& .${tooltipClasses.tooltip}`]: { fontSize: '13px' } }))

  return (
    <>
      {visible &&
        <BootstrapTooltip
          disableHoverListener={open}
          placement='right'
          title={name}
          arrow
        >
          <ListItemButton
            onClick={() => { if (closeIf && !closeIf[1]) { closeIf[0]() } }}
            sx={{
              borderRadius: 2,
              px: 1.5,
              py: 1,
              m: 0.5,
              '&.Mui-selected': {
                color: 'black',
                backgroundColor: colors.primary,
                '& .MuiListItemIcon-root': { color: 'black' },
                '&:hover': {
                  color: 'black',
                  backgroundColor: colors.primary
                }
              },
              '&:hover': {
                color: 'black',
                backgroundColor: colors.primary,
                '& .MuiListItemIcon-root': {
                  color: 'black'
                }
              }
            }}
            selected={window.location.pathname === to}
            component={NavLink}
            disabled={disabled}
            to={to}
          >
            <ListItemIcon>
              <Icon />
            </ListItemIcon>
            <ListItemText sx={{ whiteSpace: 'pre', m: 0, p: 0 }} primary={name} />
          </ListItemButton>
        </BootstrapTooltip>}
    </>
  )
}

export default SideListItem
