import React from 'react'
import { Tooltip, Typography, IconButton, Menu, MenuItem, ListItemIcon, Divider } from '@mui/material'
import { UserCheck, PieChart, Settings, User, Home, AtSign, Sun, Moon, LogOut, Key, AlertTriangle, HelpCircle, MoreVertical, MessageCircle } from 'react-feather'
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state'
import { useNavigate } from 'react-router-dom'

import { userTypes } from '../services/userType'
import { useAuth, useTheme } from '../hooks'
import packa from '../../package.json'

const MenuComp = ({ version }) => {
  const { handleDark, dark } = useTheme()
  const { signOut, user, type } = useAuth()
  const navigate = useNavigate()

  return (
    <PopupState variant='popover' popupId='popup-menu'>
      {(popupState) => (
        <>
          <IconButton color='inherit' aria-label='Sair' {...bindTrigger(popupState)}>
            <MoreVertical size={15} />
          </IconButton>
          <Menu onClose={() => popupState.close()} {...bindMenu(popupState)}>
            <MenuItem disabled>
              <ListItemIcon>
                <User size={15} />
              </ListItemIcon>
              <Typography color='inherit'>
                {user.name}
                <br />
                <Typography color='inherit' variant='caption'>
                  {userTypes[type.type]}
                  {type.company ? ` - ${type.company.name}` : ''}
                </Typography>
              </Typography>
            </MenuItem>

            <MenuItem disabled>
              <ListItemIcon>
                <AtSign size={15} />
              </ListItemIcon>
              <Typography color='inherit'>
                {user.username}
              </Typography>
            </MenuItem>

            {user.super &&
              <div>
                <Divider />
                <MenuItem disabled>
                  Super
                </MenuItem>
                <MenuItem onClick={() => { popupState.close(); navigate('/fale-conosco/lista') }}>
                  <ListItemIcon>
                    <MessageCircle size={15} />
                  </ListItemIcon>
                  Fale conosco
                </MenuItem>
                <MenuItem onClick={() => { popupState.close(); navigate('/ajuda/lista') }}>
                  <ListItemIcon>
                    <HelpCircle size={15} />
                  </ListItemIcon>
                  Ajuda
                </MenuItem>
                <MenuItem onClick={() => { popupState.close(); navigate('/auditoria') }}>
                  <ListItemIcon>
                    <AlertTriangle size={15} />
                  </ListItemIcon>
                  Auditoria
                </MenuItem>
                <MenuItem onClick={() => { popupState.close(); navigate('/dashboard') }}>
                  <ListItemIcon>
                    <PieChart size={15} />
                  </ListItemIcon>
                  Dashboard
                </MenuItem>
                <MenuItem onClick={() => { popupState.close(); navigate('/configuracoes') }}>
                  <ListItemIcon>
                    <Settings size={15} />
                  </ListItemIcon>
                  Configurações
                </MenuItem>
              </div>}

            <Divider />
            {type?.type === 'MAN' && (
              <div>
                <MenuItem onClick={() => { popupState.close(); navigate('/pre') }}>
                  <ListItemIcon>
                    <UserCheck size={15} />
                  </ListItemIcon>
                  Pré-cadastro
                </MenuItem>
                <Divider />
              </div>
            )}

            <MenuItem onClick={handleDark}>
              <ListItemIcon>
                <Tooltip title={`FRONTEND_${packa.version} / BACKEND_${version}`} placement='right' enterDelay={1000}>
                  {dark ? <Sun size={15} /> : <Moon size={15} />}
                </Tooltip>
              </ListItemIcon>
              {dark ? 'Light mode' : 'Night mode'}
            </MenuItem>

            <MenuItem
              onClick={() => {
                popupState.close()
                navigate('/perfil')
              }}
            >
              <ListItemIcon>
                <Home size={15} />
              </ListItemIcon>
              Perfil
            </MenuItem>
            <MenuItem
              onClick={() => {
                popupState.close()
                navigate('/trocar/senha')
              }}
            >
              <ListItemIcon>
                <Key size={15} />
              </ListItemIcon>
              Trocar senha
            </MenuItem>
            <MenuItem onClick={signOut}>
              <ListItemIcon>
                <LogOut size={15} />
              </ListItemIcon>
              Sair
            </MenuItem>
          </Menu>
        </>)}
    </PopupState>
  )
}

export default MenuComp
